<template>
        <div class="media" v-if="store">
            <!-- <router-link :to="'/store/'+store._id"> -->
            <a @click="redirectToStore()">
            <div class="media-left media-middle col-lg-4 pointer top-store-img" >
                <img
                        v-if="store.image"
                        :src="$base_url +store.image"
                        class="img-thumbnail"
                        alt
                />
                <img
                        v-else
                        class="img-responsive"
                        src="../../assets/placeholder.png"
                        alt
                />
            </div>
            </a>
            <div class="media-body top-store-text" v-if="store" >
                <a @click="redirectToStore()" class="pointer">
                <h4 class="media-heading" style="margin-top: 12px" >{{ store.name||"-" }}</h4>
                </a>
                <p>{{store.coupons_count}} Coupons Available</p>
            </div>
        </div>
</template>

<script>
    export default {
        name: 'DisplayStoresHorizontally',
        props: ["store"],
  methods: {
    redirectToStore() {
      this.$router.push({
        name: "SearchStore",
        params: { code: this.store._id,slug: this.store.slug },
      });
    },
  },
};
</script>

<style scoped>
   
</style>